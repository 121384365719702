import React, { useContext, useState } from "react";
import SideIcon from "../../src/assets/png/c162_right_alignment.png";
import { ReactComponent as MeetingsRoomIcon } from "../../src/assets/svg/MeetingsRoomIcon.svg";
import { ReactComponent as CoachesIcon } from "../../src/assets/svg/CoachesIcon.svg";
import { ReactComponent as BookingRequestsIcon } from "../../src/assets/svg/BookingRequestsIcon.svg";
import { ReactComponent as NewsIcon } from "../../src/assets/svg/NewsIcon.svg";
import { ReactComponent as SettingsIcon } from "../../src/assets/svg/SettingsIcon.svg";
import { ReactComponent as StaffIcon } from "../../src/assets/svg/StaffIcon.svg";
import { ReactComponent as UserRequestIcon } from "../../src/assets/svg/userreq.svg";
import { ReactComponent as PolicyIcon } from "../../src/assets/svg/policy.svg"
import { ReactComponent as EventsIcon } from "../../src/assets/svg/event_icon_black.svg";
import { ReactComponent as AssetsIcon } from "../../src/assets/svg/assets.svg";
import { ReactComponent as SurveyIcon } from "../../src/assets/svg/surveyform.svg";
import AddModeratorIcon from '@mui/icons-material/AddModerator';

import { Link } from "react-router-dom";
import "./Sidebar.css";
import UserRequests from "../pages/userrequests/UserRequests";

function Sidebar() {

  const linksItems = [
    {
      id: 1,
      to: "/meetingrooms",
      linkText: "Meeting Rooms",
      icon: <MeetingsRoomIcon />,
    },
    {
      id: 2,
      to: "/coaches",
      linkText: "Coaches",
      icon: <CoachesIcon />,
    },
    {
      id: 3,
      to: "/bookingrequests",
      linkText: "Booking Requests",
      icon: <BookingRequestsIcon />,
    },
    {
      id: 4,
      to: "/review-moderation",
      linkText: "Review Moderation",
      icon: <AddModeratorIcon />,
    },
    {
      id: 5,
      to: "/news",
      linkText: "News",
      icon: <NewsIcon />,
    },
    {
      id: 6,
      to: "/settings",
      linkText: "Settings",
      icon: <SettingsIcon />,
    },
    {
      id: 7,
      to: "/staff",
      linkText: "Staff",
      icon: <StaffIcon />,
    },
    {
      id: 8,
      to: "/userrequests",
      linkText: "User Requests",
      icon: <UserRequestIcon />,
    },
    {
      id: 9,
      to: "/policy",
      linkText: "Policy",
      icon: <PolicyIcon />
    },
    {
      id: 10,
      to: "/events",
      linkText: "Events",
      icon: <EventsIcon />,
    },
  	{
      id: 11,
      to: "/comingsoon",
      linkText: "Assets",
      icon: <AssetsIcon />,
    },
  	{
      id: 12,
      to: "/comingsoon",
      linkText: "Survey",
      icon: <SurveyIcon />,
    }
  ];

  const [selectedLink, setSelectedLink] = useState(
    localStorage.getItem("selectedLink") || ""
  );

  const handleLinkClick = (linkId) => {
    setSelectedLink(linkId);
    // console.log(link);
    localStorage.setItem("selectedLink", linkId);
  };
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleIconClick = () => {
    setIsCollapsed(!isCollapsed);
    console.log("animation for sidebar", isCollapsed)
  };

  const userData = JSON.parse(localStorage.getItem("userData"));
  const userEmail = userData?.email;
  const allowedEmails = [
    'support@axzoragroup.com'
  ];


  return (
    <>
      <div className={`sideBarLinksWrapper ${isCollapsed ? 'collapsed' : ''}`}>
        <div className="sideIconsection" id="linksToggleId" onClick={handleIconClick}>
          <img src={SideIcon} alt="" />
        </div>
        <div className="linksWrapper">
          {linksItems.map((links) => (
            allowedEmails.includes(userEmail) || links.id !== 9 ? ( 
            <Link
              key={links.id}
              to={links.to}
              onClick={() => handleLinkClick(links.id.toString())}
              className={`sideBarLink ${selectedLink === links.id.toString() ? "selected" : ""
                }`}
              id="navigationLinksId"

            >
              <div className="linkTxtIconClass">
                {links.icon}
              </div>
              <div className={`linkIconClass `}>
                <p>{links.linkText}</p>
              </div>
            </Link> ) : null
          ))}
        </div>
      </div>
    </>
  );
}

export default Sidebar;
